import React from 'react'
import { Icon } from 'semantic-ui-react'
// custom
import { getSemanticColors } from '../helpers/utils'

export default function TabHeadTitle({ name, iconName, index, isActive }) {
  return (
    <span>
      <Icon
        className={`${iconName} icon ${
          isActive ? getSemanticColors(index) : null
        }`}
        style={{ marginRight: `.5rem` }}
        aria-hidden="true"
      />
      {name}
    </span>
  )
}
