import React, { useState, useContext } from 'react'
import Loadable from 'react-loadable'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import 'react-quill/dist/quill.snow.css'
// custom
import { PickOrderContext } from './../ProviderPickOrder'
import TabHeadTitle from './TabHeadTitle'

const ReactQuill = Loadable({
  loader: () => import('react-quill'),
  loading() {
    return <div>loading ...</div>
  },
})

export default function SectionMenuEditTabs() {
  const context = useContext(PickOrderContext)
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
      style={{ width: `100%` }}
    >
      <TabList>
        <Tab>
          <TabHeadTitle
            name="Item"
            iconName="edit"
            index={0}
            isActive={tabIndex === 0}
          />
        </Tab>
        <Tab>
          <TabHeadTitle
            name="Options"
            iconName="tags"
            index={1}
            isActive={tabIndex === 1}
          />
        </Tab>
      </TabList>
      {/* #0.1 menu editor */}
      <TabPanel>
        <ReactQuill
          id="menu-editor"
          theme="snow"
          value={context.menuHtml}
          onChange={context.setMenuHtml}
          modules={{
            toolbar: [['bold']],
          }}
          formats={['bold']}
        />
      </TabPanel>
      {/* #0.2 tag editor */}
      <TabPanel>
        <ReactQuill
          id="tag-editor"
          theme="snow"
          value={context.tagHtml}
          onChange={context.setTagHtml}
          modules={{
            toolbar: false,
          }}
          formats={['bold']}
        />
      </TabPanel>
    </Tabs>
  )
}
