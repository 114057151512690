import React, { useEffect, useContext } from 'react'
import { map, get, isEmpty } from 'lodash'
import { toast } from 'react-toastify'
// custom
import { PickOrderContext } from './ProviderPickOrder'
import { getMenuByIndex } from './api/services'
import { replaceUrl } from './helpers/utils'

export default function MenuInit() {
  const context = useContext(PickOrderContext)

  // common
  const setNewMenu = telephone => {
    replaceUrl(`/menu`)
    context.setPageData({
      telephone,
    })
    context.setSidebarUp(true)
    toast.info('1. new record *')
  }
  // flows
  const initMenu = () => {
    const _urlTelephone = new URL(window.location.href).searchParams.get('tel')
    // 1. new record without URL tel
    if (!_urlTelephone) {
      setNewMenu(_urlTelephone)
      context.setMenuHtml('')
      context.setTagHtml('')
      return
    }
    // 2. fetch by URL tel
    context.setLoading(true)
    fetchMenu(_urlTelephone)
  }
  const fetchMenu = tel =>
    getMenuByIndex('telephone', tel)
      .then(res => {
        if (isEmpty(res)) {
          // tel NOT in record
          setNewMenu(tel)
          return
        }
        // tel in record
        context.setMenuHtml(get(res, `[0].data.menuHtml`))
        context.setTagHtml(get(res, `[0]data.tagHtml`))
        context.setMenuKey(get(res, `[0].ref.value.id`, false))
        context.setPageData(get(res, `[0].data`))
        // token handle
        const urlToken = new URL(window.location.href).searchParams.get('token')
        const token = get(res, `[0]data.token`)
        if (urlToken === token) {
          // user with correct token
          replaceUrl(`/menu?tel=${tel}&token=${token}`)
          context.setIsMerchant(true)
          return
        }
        const isMerchant = get(res, `[0]data.isMerchant`)
        if (isMerchant) {
          // user without correct token
          context.setMerchantLock(true)
        }
        // free edit record
        replaceUrl(`/menu?tel=${tel}`)
      })
      .finally(() => context.setLoading(false))

  // onLoad
  useEffect(() => {
    const initDemoMenu = () =>
      getMenuByIndex('category', 'demo').then(res => {
        const options = map(res, item => {
          return {
            key: item?.ref?.value?.id,
            value: item?.ref?.value?.id,
            text: item?.data?.name,
          }
        })
        context.setDummyOptions(options)
      })
    initDemoMenu()
    initMenu()
  }, []) // eslint-disable-line
  return <></>
}
