import React, { useContext } from 'react'
import { isEmpty, size, orderBy } from 'lodash'
import { Button as ButtonSemantic, Icon } from 'semantic-ui-react'
import shortid from 'shortid'
// custom
import {
  handleSectionHeight,
  getSemanticColors,
  filterOutTag,
} from '../helpers/utils'
import { PickOrderContext } from '../ProviderPickOrder'
import { appConfig } from '../../../config/app-config'
import { Button } from '../helpers/styled-components'

export default function ListMenu() {
  const context = useContext(PickOrderContext)
  // handler
  const removeTagHander = (cartIndex, itemTag) => {
    context.setCurrentCartKey(cartIndex)
    context.setCart(cart =>
      cart.map((item, index) => {
        if (index === cartIndex) {
          item.tags = filterOutTag(item, itemTag)
        }
        return item
      })
    )
  }
  const removeItemHander = index => {
    const newCart = context.cart.filter((item, key) => key !== index)
    context.setCart(newCart)
    // focus UX handle
    if (isEmpty(newCart)) {
      context.setCurrentCartKey(null)
      return
    }
    if (index > context.currentCartKey) {
      return
    }
    const isFocusedItem = index === context.currentCartKey
    if (isFocusedItem) {
      const isLastItem = index + 1 === size(context.cart)
      if (isLastItem) {
        context.setCurrentCartKey(index - 1)
      } else {
        context.setCurrentCartKey(index)
      }
      return
    }
    context.setCurrentCartKey(context.currentCartKey - 1)
  }

  return (
    <section
      id="cart"
      className="row"
      style={{
        marginTop: `1rem`,
        padding: `0 2rem`,
        height: `${handleSectionHeight(context.layoutCartExtend, 10)}rem`,
        width: `100%`,
        background: appConfig.themeBackground,
        position: `relative`,
      }}
    >
      {/* 2.9 expand button */}
      <ButtonSemantic
        size="mini"
        icon={
          context.layoutCartExtend ? `angle double down` : `angle double up`
        }
        style={{
          zIndex: 1,
          position: `absolute`,
          left: `0.5rem`,
          bottom: 0,
          background: context.layoutCartExtend
            ? `rgba(0,0,0,${appConfig?.iconOpacity})`
            : `white`,
          color: context.layoutCartExtend ? appConfig.themeBackground : `grey`,
          fontSize: `3rem`,
          padding: 0,
        }}
        onClick={() => context.setLayoutCartExtend(!context.layoutCartExtend)}
      />
      <fieldset
        style={{
          border: `2px solid`,
          height: `100%`,
          overflow: `hidden`,
          position: `relative`,
        }}
      >
        <legend style={{ padding: `0 1rem`, height: `2rem` }}>
          <Icon name="cart" />
          <span>CART</span>
          <Button
            basic
            size="mini"
            style={{
              marginLeft: `.5rem`,
            }}
            onClick={() => context.setCart([])}
            disabled={isEmpty(context.cart)}
          >
            <Icon
              key={shortid.generate()}
              name="trash"
              color="red"
              className="ref-keep-tag-options"
              style={{ margin: 0 }}
            />
          </Button>
        </legend>
        {context.cart && (
          <dl
            id="cart-container"
            style={{
              margin: 0,
              listStyleType: `none`,
              height: `100%`,
              overflowY: `auto`,
            }}
          >
            {context.cart.map((item, cartIndex) => (
              <div
                key={cartIndex}
                style={{
                  marginBottom: `.5rem`,
                  minHeight: `3rem`,
                  lineHeight: `2rem`,
                }}
              >
                {/* 2.1 remove cart item button */}
                <Button
                  size="mini"
                  basic
                  onClick={() => removeItemHander(cartIndex)}
                >
                  <Icon
                    name="delete"
                    color="red"
                    className="ref-keep-tag-options"
                    style={{ margin: 0 }}
                  />
                </Button>
                {/* 2.2 cart item name */}
                <span
                  aria-hidden="true"
                  onClick={() => context.setCurrentCartKey(cartIndex)}
                  className="ref-keep-tag-options"
                  style={{
                    fontSize: `1.5rem`,
                    marginLeft: `.5rem`,
                    borderBottom:
                      context.currentCartKey === cartIndex
                        ? `1px solid`
                        : `none`,
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {item.name}
                </span>
                {/* 2.3 tags options button */}
                {isEmpty(context.tags) ? (
                  <></>
                ) : (
                  <button
                    className="ui simple dropdown item ref-keep-tag-options"
                    onClick={() => {
                      context.setCurrentCartKey(cartIndex)
                      context.setShowTags(true)
                    }}
                    style={{ border: 0, background: `transparent` }}
                    tabIndex={0}
                  >
                    <Icon
                      name="tags"
                      className="ref-keep-tag-options"
                      style={{ opacity: 0.2 }}
                    />
                  </button>
                )}
                {/* 2.4 tags */}
                {item.tags &&
                  orderBy(item.tags, ['groupId']).map(itemTag => (
                    <div
                      key={shortid.generate()}
                      className="ui label ref-keep-tag-options"
                    >
                      {/* 2.41 dot */}
                      <span
                        className={`ref-keep-tag-options mini ui empty circular label ${getSemanticColors(
                          itemTag.groupId
                        )}`}
                      />
                      {/* 2.42 tag name */}
                      <span
                        className="ref-keep-tag-options"
                        style={{
                          marginLeft: `.5rem`,
                          fontWeight: `normal`,
                        }}
                      >
                        {itemTag.name}
                      </span>
                      {/* 2.43 remove tag button */}
                      <Icon
                        name="delete"
                        className="ref-keep-tag-options"
                        onClick={() => removeTagHander(cartIndex, itemTag)}
                        aria-hidden="true"
                        role="button"
                      />
                    </div>
                  ))}
              </div>
            ))}
          </dl>
        )}
      </fieldset>
    </section>
  )
}
