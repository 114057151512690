import React, { useContext } from 'react'
import { Input, Dropdown, Button } from 'semantic-ui-react'
import { isEmpty, get } from 'lodash'
import { toast } from 'react-toastify'
// custom
import { PickOrderContext } from '../ProviderPickOrder'
import { appConfig } from '../../../config/app-config'
import {
  getMenuByKey,
  getMenuByIndex,
  updateMenuItem,
  createMenuItem,
} from '../api/services'
import { cleanHtml, isTelephone, replaceUrl } from '../helpers/utils'

export default function SectionMenuConfig() {
  const context = useContext(PickOrderContext)

  const updateMenuHandle = () => {
    context.setLoading(true)
    const { telephone } = context.pageData
    const data = {
      ...context.pageData,
      menuHtml: context.menuHtml,
      tagHtml: context.tagHtml,
    }

    if (isEmpty(telephone)) {
      toast.error(`please fill in telephone`)
      return
    }

    // query record first. then condition for create/update
    getMenuByIndex('telephone', telephone)
      .then(res => {
        if (isEmpty(res)) {
          // 1. store as new record
          createMenuItem(data).then(res => {
            replaceUrl(`/menu?tel=${telephone}`)
            toast.success(`created new menu for tel: ${telephone}`)
          })
          return
        }
        // 2. update to relate record
        updateMenuItem(get(res, `[0].ref.value.id`), data).then(res => {
          toast.info('saved')
        })
      })
      .finally(() => context.setLoading(false))
  }

  const handleDemoSelect = (e, { value }) => {
    context.setLoading(true)
    getMenuByKey(value)
      .then(res => {
        context.setMenuHtml(get(res, `[0].data.menuHtml`))
        context.setTagHtml(get(res, `[0]data.tagHtml`))
      })
      .finally(() => context.setLoading(false))
  }

  return (
    <>
      <div
        style={{
          display: `flex`,
          marginBottom: `1rem`,
          position: `sticky`,
          top: 0,
          zIndex: 1,
          background: `white`,
        }}
      >
        {/* 1.1 btn: new */}
        <Button
          icon="file outline"
          onClick={() => {
            context.setMenuHtml(null)
            context.setTagHtml(null)
          }}
          style={{
            background: `transparent`,
            fontSize: `1.7rem`,
            top: 0,
            padding: 0,
            color: `var(--button-color)`,
          }}
          className="_pointer"
          disabled={
            (isEmpty(cleanHtml(context.menuHtml)) &&
              isEmpty(cleanHtml(context.tagHtml))) ||
            context.loading
          }
        />
        {/* 1.2 btn: save */}
        <Button
          icon="save"
          onClick={() => updateMenuHandle()}
          className="_pointer"
          style={{
            background: `transparent`,
            fontSize: `2rem`,
            top: 0,
            padding: 0,
            color: `var(--button-color)`,
          }}
          disabled={context.loading || !context.pageData?.telephone}
        />
        {/* 1.4 dropdown: dummy options */}
        <Dropdown
          selection
          placeholder="import demo"
          options={context.dummyOptions}
          onChange={handleDemoSelect}
          selectOnBlur={false}
          disabled={context.loading}
        />
        <Button
          icon="close"
          onClick={() => context.setSidebarUp(false)}
          style={{
            position: 'absolute',
            top: 0,
            right: `-0.5rem`,
            background: `transparent`,
            fontSize: `2rem`,
            padding: 0,
            opacity: appConfig?.iconOpacity,
          }}
        />
      </div>
      <div
        style={{
          marginBottom: `1rem`,
        }}
      >
        {/* 2.0 token */}
        {context.isMerchant && (
          <div>
            <Input
              label={{ icon: 'key' }}
              labelPosition="left corner"
              defaultValue={context.pageData?.token}
              placeholder="token"
              onChange={e =>
                context.setPageData({
                  ...context.pageData,
                  token: e.target.value,
                })
              }
              className="_z0"
              style={{
                maxWidth: `40%`,
                marginRight: `1rem`,
              }}
            />
          </div>
        )}
        {/* 2.1 organization */}
        <Input
          label={{ icon: 'food' }}
          labelPosition="left corner"
          defaultValue={context.pageData?.name}
          placeholder="organization"
          onChange={e =>
            context.setPageData({
              ...context.pageData,
              name: e.target.value,
            })
          }
          className="_z0"
          style={{
            maxWidth: `40%`,
            marginRight: `1rem`,
          }}
        />
        {/* 2.2 telephone */}
        <Input
          label={{ icon: 'phone' }}
          labelPosition="left corner"
          defaultValue={context.pageData?.telephone}
          placeholder="telephone"
          onChange={e => {
            context.setPageData({
              ...context.pageData,
              telephone: e.target.value,
            })
          }}
          className="_z0"
          style={{
            maxWidth: `40%`,
          }}
          type="number"
          error={!isTelephone(context.pageData?.telephone)}
        />
      </div>
    </>
  )
}
