import React from 'react'
import { Sidebar, Menu } from 'semantic-ui-react'
import shortid from 'shortid'
import { css } from '@emotion/react'
// custom
import { getSemanticColors } from '../helpers/utils'
import { Button } from '../helpers/styled-components'

export default function SidebarOptions({ show, tags, sidebarOptionsClickTag }) {
  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      visible={Boolean(show)}
      width="wide"
      direction="bottom"
    >
      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          flexWrap: `wrap`,
          height: `6rem`,
        }}
      >
        {tags &&
          tags.map(tagItem => (
            <Button
              key={shortid.generate()}
              className="item"
              onClick={() => sidebarOptionsClickTag(tagItem)}
              role="button"
              tabIndex={0}
              style={{
                height: `3rem`,
                margin: 0,
                borderRight: `1px dashed  rgba(var(--main-color), 0.5)`,
                borderBottom: `1px dashed  rgba(var(--main-color), 0.5)`,
              }}
              css={css`
                &:before {
                  display: none !important;
                }
              `}
            >
              <span
                className={`ui empty circular label ${getSemanticColors(
                  tagItem.groupId
                )}`}
                style={{ marginRight: `.5rem` }}
              />
              {tagItem.name}
            </Button>
          ))}
      </div>
    </Sidebar>
  )
}
