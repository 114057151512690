import React, { useEffect, useContext } from 'react'
import { includes, size, xor, map } from 'lodash'
import { Sidebar } from 'semantic-ui-react'
// custom
import { getHtmlData, filterOutTag } from './helpers/utils'
import { PickOrderContext } from './ProviderPickOrder'
import SidebarTop from './SidebarTop'
import SidebarBottom from './SidebarBottom'
import SidebarOptions from './SidebarBottom/SidebarOptions'
import ListMenu from './body/ListMenu'
import ListCart from './body/ListCart'
import ShortcutKeys from './ShortCutKeys'
import MenuInit from './MenuInit'

export default function UserOrder() {
  const context = useContext(PickOrderContext)

  // html tag handle
  useEffect(() => {
    const data = getHtmlData(context.tagHtml)
    let stack = []
    map(data, (item, key) => {
      map(item, i => {
        stack.push({ groupId: key, name: i })
      })
    })
    context.setTags(stack)
  }, [context.tagHtml]) //eslint-disable-line
  // html menu handle
  useEffect(() => {
    const data = getHtmlData(context.menuHtml)
    let stack = []
    map(data, item => {
      if (includes(item[0], '<strong>')) {
        const titleName = item[0]
          .replaceAll('<strong>', '')
          .replaceAll('</strong>', '')
        stack.push({
          name: titleName,
          type: `title`,
        })
        return
      }
      stack.push({ name: item })
    })
    context.setMenu(stack)
  }, [context.menuHtml]) // eslint-disable-line
  // sidebar toggle handle
  useEffect(() => {
    if (context.sidebarUp) {
      context.setSidebarDown(false)
      context.setShowTags(false)
    }
  }, [context.sidebarUp]) // eslint-disable-line
  useEffect(() => {
    if (context.sidebarDown) {
      context.setSidebarUp(false)
      context.setShowTags(false)
    }
  }, [context.sidebarDown]) // eslint-disable-line
  useEffect(() => {
    if (context.showTags) {
      context.setSidebarUp(false)
      context.setSidebarDown(false)
    }
  }, [context.showTags]) // eslint-disable-line

  // cart handle
  useEffect(() => {
    if (!context.scrollToCartBottom) {
      return
    }
    context.setScrollToCartBottom(false)
    const cartContainer = document.querySelector('#cart-container')
    cartContainer.scrollTop = cartContainer.scrollHeight
  }, [context.cart]) //eslint-disable-line

  // SSR handle
  if (typeof window === undefined) {
    return <></>
  }
  return (
    <>
      <MenuInit />
      <Sidebar.Pushable
        style={{
          height: `99%`,
          width: `100%`,
          position: `fixed`,
          overflow: `hidden`,
        }}
      >
        <SidebarOptions
          show={context.showTags && size(context.cart)}
          tags={context.tags}
          sidebarOptionsClickTag={tagItem =>
            context.setCart(cart =>
              cart.map((item, index) => {
                if (index === context.currentCartKey) {
                  const _tags = filterOutTag(item, tagItem)
                  item.tags = xor(_tags, [tagItem])
                }
                return item
              })
            )
          }
        />
        <SidebarTop />
        <SidebarBottom
          cart={context.cart}
          visible={Boolean(context.sidebarDown)}
          handleSidebarFooterClick={() => {
            if (!context.sidebarDown) {
              context.setSidebarDown(true)
            }
          }}
        />
        <Sidebar.Pusher
          onClick={e => {
            if (!e.target.className.includes('edit icon')) {
              context.setSidebarUp(false)
            }
            context.setSidebarDown(false)
          }}
        >
          <main
            style={{
              position: 'absolute',
              height: '99%',
              width: `100%`,
              filter:
                context.sidebarUp || context.sidebarDown
                  ? `blur(1px)`
                  : `inherit`,
            }}
            onClick={e => {
              if (
                context.showTags &&
                !e.target.className.includes('keep-tag-options')
              ) {
                context.setShowTags(false)
              }
            }}
            role="presentation"
          >
            <ListMenu />
            <ListCart />
          </main>
        </Sidebar.Pusher>
        <ShortcutKeys />
      </Sidebar.Pushable>
    </>
  )
}
