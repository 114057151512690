import { Button as ButtonSemantic } from 'semantic-ui-react'
import styled from '@emotion/styled'

export const Button = styled(ButtonSemantic)`
  border-right: 1px outset !important;
  border-bottom: 1px outset !important;
  &:active {
    border-right: 1px outset transparent !important;
    border-bottom: 1px outset transparent !important;
  }
`
