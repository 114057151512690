import React, { useContext } from 'react'
import { size } from 'lodash'
import { Button as ButtonSemantic, Icon } from 'semantic-ui-react'
import shortid from 'shortid'
// custom
import { handleSectionHeight, getSemanticColors } from './../helpers/utils'
import { PickOrderContext } from '../ProviderPickOrder'
import { appConfig } from '../../../config/app-config'
import { Button } from '../helpers/styled-components'

export default function ListMenu() {
  const context = useContext(PickOrderContext)

  return (
    <section
      id="menu-view"
      style={{
        height: `calc(100% - ${handleSectionHeight(
          context.layoutCartExtend,
          18
        )}rem)`,
        marginTop: `1rem`,
        padding: `0 2rem`,
        overflow: `hidden`,
        background: appConfig.themeBackground,
        position: 'relative',
      }}
      role="button"
      tabIndex={0}
    >
      {/* 1.9 edit button */}
      {!context.merchantLock && (
        <ButtonSemantic
          icon={context.sidebarUp ? `edit outline` : `edit`}
          onClick={() => context.setSidebarUp(true)}
          style={{
            position: `absolute`,
            left: `1.5rem`,
            bottom: 0,
            background: context.sidebarUp ? `rgba(0,0,0,.75)` : `white`,
            color: context.sidebarUp ? appConfig.themeBackground : `grey`,
            padding: 0,
            fontSize: `2rem`,
          }}
        />
      )}
      <fieldset
        style={{
          border: `2px solid`,
          height: `100%`,
        }}
      >
        <legend style={{ padding: `0 1rem` }}>
          <Icon name="tasks" />
          <span>
            MENU{` `}
            {size(context?.pageData?.name) ? `- ${context.pageData.name}` : ``}
          </span>
        </legend>
        {context.menu && (
          <div
            id="menu-container"
            style={{
              margin: 0,
              maxHeight: `100%`,
              overflowY: `auto`,
            }}
          >
            {context.loading && (
              <Button basic color="grey">
                loading...
              </Button>
            )}
            {context.menu &&
              context.menu.map((menuItem, menuKey) =>
                menuItem?.type === 'title' ? (
                  <h3
                    key={shortid.generate()}
                    style={{
                      textAlign: `center`,
                      borderBottom: `1px solid lightgray`,
                      textTransform: `uppercase`,
                    }}
                  >
                    {menuItem.name}
                  </h3>
                ) : (
                  <Button
                    basic
                    key={shortid.generate()}
                    color="grey"
                    className="ref-btn-menu ref-keep-tag-options"
                    css={`
                      margin: 0 0.5rem 0.5rem 0 !important;
                    `}
                    onClick={() => {
                      context.setCart(item => [
                        ...item,
                        { name: menuItem.name },
                      ])
                      context.setCurrentCartKey(context.cart.length)
                      context.setScrollToCartBottom(true)
                    }}
                    disabled={context.loading}
                  >
                    <span
                      className={`ref-keep-tag-options mini ui empty circular label ${getSemanticColors(
                        menuKey
                      )}`}
                    />
                    <span style={{ marginLeft: `.5rem` }}>{menuItem.name}</span>
                  </Button>
                )
              )}
          </div>
        )}
      </fieldset>
    </section>
  )
}
