import React from 'react'
import { Sidebar, Menu } from 'semantic-ui-react'
import { Global, css } from '@emotion/react'
// custom
import SectionExportCart from './SectionExportCart'

export default function SidebarBottom({
  visible,
  cart,
  handleSidebarFooterClick,
}) {
  return (
    <Sidebar
      as={Menu}
      animation="scale down"
      visible={Boolean(visible)}
      width="wide"
      direction="bottom"
      onClick={() => handleSidebarFooterClick()}
    >
      <Global
        styles={css`
          .ui.bottom.scale.down.sidebar:not(.visible) {
            transform: translate3d(0, 70%, 0);
            visibility: visible;
            z-index: 101;
          }
          .ui.scale.down.sidebar {
            transition: transform 0.1s ease, -webkit-transform 0.1s ease;
          }
        `}
      />
      <SectionExportCart cart={cart} />
    </Sidebar>
  )
}
