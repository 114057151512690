import React, { useContext } from 'react'
import { Sidebar, Menu, Icon } from 'semantic-ui-react'
import { Global, css } from '@emotion/react'
// custom
import { PickOrderContext } from './../ProviderPickOrder'
import SectionMenuConfig from './SectionMenuConfig'
import SectionMenuEditTabs from './SectionMenuEditTabs'

export default function SidebarTop() {
  const context = useContext(PickOrderContext)

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      visible={context.sidebarUp}
      width="very wide"
      direction="top"
    >
      <Global
        styles={css`
          .ui.top.overlay.sidebar {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
              rgba(0, 0, 0, 0.24) 0px 1px 2px;
          }
        `}
      />
      <section
        id="menu-edit"
        className="row"
        style={{ width: `100%`, padding: `1rem` }}
      >
        <fieldset
          style={{
            border: `2px solid`,
          }}
        >
          <legend style={{ padding: `0 1rem` }}>
            <Icon name="edit outline" />
            <span>EDIT MENU</span>
          </legend>
          <SectionMenuConfig />
          <SectionMenuEditTabs />
        </fieldset>
      </section>
    </Sidebar>
  )
}
