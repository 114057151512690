import React, { useEffect, useContext, useCallback } from 'react'
import { PickOrderContext } from './ProviderPickOrder'

export default function ShortCutKeys() {
  const { setSidebarUp, setSidebarDown } = useContext(PickOrderContext)

  const escFunction = useCallback(event => {
    const keyEsc = 27
    if (event.keyCode === keyEsc) {
      setSidebarUp(false)
      setSidebarDown(false)
    }
  }, []) // eslint-disable-line

  // onload
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, []) // eslint-disable-line

  return <></>
}
