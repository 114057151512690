import React from 'react'
// custom
import UserOrder from '../components/user-order'
import ProviderPickOrder from '../components/user-order/ProviderPickOrder'
import Layout from '../components/user-order/Layout'

export default function MenuPage() {
  return (
    <ProviderPickOrder>
      <Layout>
        <UserOrder path="/menu" />
      </Layout>
    </ProviderPickOrder>
  )
}
