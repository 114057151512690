import React, { useState, useContext, useEffect } from 'react'
import { truncate, size, isEmpty, map } from 'lodash'
import { Button, Input, Icon } from 'semantic-ui-react'
// custom
import { appConfig } from '../../../config/app-config'
import { PickOrderContext } from '../ProviderPickOrder'

export default function SectionExportCart({ cart }) {
  const AREA_CODE = 852
  const context = useContext(PickOrderContext)
  const [telephone, setTelephone] = useState()
  const disableButtons = size(telephone) < appConfig.telephoneMinDigi

  const whatsappHandle = () => {
    const baseEndpoint = `https://wa.me/${AREA_CODE}${telephone}`
    if (isEmpty(cart)) {
      window.open(baseEndpoint, '_blank').focus()
      return
    }
    const message = cart
      .map(item => {
        const _tags = isEmpty(item.tags)
          ? ''
          : map(item.tags, item => ` [${item.name}]`)
        const content = (item.name + _tags).replaceAll(',', '')
        return encodeURIComponent(content)
      })
      .join(`%0a`) // line break
    const whatsappEndpoint = truncate(`${baseEndpoint}?text=` + message, {
      length: 2000,
    })
    window.open(whatsappEndpoint, '_blank').focus()
  }

  useEffect(() => {
    if (isEmpty(telephone)) {
      // init by merchant telephone
      setTelephone(context.pageData?.telephone)
    }
  }, [telephone, context.pageData?.telephone])

  return (
    <form
      onSubmit={e => e.preventDefault()}
      style={{ background: `white`, padding: `1rem 2rem`, width: `100%` }}
    >
      <fieldset style={{ border: `2px solid` }}>
        <legend style={{ padding: `0 1rem` }}>
          <Icon className="share alternate icon" />
          <span>EXPORT</span>
        </legend>
        <div>
          <Input
            id="input-telephone"
            size="large"
            label={AREA_CODE}
            type="number"
            min="0"
            max="99899999"
            placeholder="telephone number"
            style={{ margin: `0.5rem 1rem 0.5rem 0` }}
            onChange={e => {
              setTelephone(e.target.value)
            }}
            defaultValue={context.pageData?.telephone}
          />

          <Button
            id="btn-whatsapp"
            type="submit"
            color="green"
            onClick={() => whatsappHandle()}
            disabled={disableButtons}
            style={{ border: `1px solid rgba(34,36,38,.15)` }}
          >
            <Icon className="whatsapp icon" style={{ margin: 0 }} />
          </Button>
          <Button
            id="btn-telephone"
            color="blue"
            disabled={disableButtons}
            onClick={() => window.open(`tel:${telephone}`)}
            style={{ border: `1px solid rgba(34,36,38,.15)` }}
          >
            <Icon className="phone icon" />
          </Button>
        </div>
      </fieldset>
    </form>
  )
}
